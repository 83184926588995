module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/pierre/workspace/mychristina.net/src/components/Layout/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"M/Y Christina","short_name":"M/Y Christina","start_url":"/","background_color":"#F4F6F8","theme_color":"#2c8bff","display":"standalone","icon":"./src/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"19a11ec9a0589618a9470d9dc296360b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
