import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Touchable from '@components/Touchable'

import styles from './CategoryLink.module.css'

const CategoryLink = ({ className, style, children, ...rest }) => (
  <Touchable className={cx(styles.root, className)} style={style} {...rest}>
    {children}
  </Touchable>
)

CategoryLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default CategoryLink
