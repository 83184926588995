import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './CategoryLabel.module.css'

const CategoryLabel = ({ iconBefore, className, style, children }) => {
  const Icon = iconBefore
  return (
    <span className={cx(styles.root, className)} style={style}>
      {iconBefore && <Icon className={styles.icon} />}
      {children}
    </span>
  )
}

CategoryLabel.propTypes = {
  iconBefore: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default CategoryLabel
