import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import styles from './Sticky.module.css'

const Sticky = ({ top, bottom, className, style, children }) => (
  <div style={{ top, bottom, ...style }} className={cx(styles.root, className)}>
    {children}
  </div>
)

Sticky.propTypes = {
  top: PropTypes.number,
  bottom: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Sticky
