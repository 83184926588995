import React from 'react'

const JumboImage = () => (
  <picture
    style={{
      display: 'block',
      overflow: 'hidden',
      paddingBottom: '29.166666666666668%',
      position: 'relative',
      width: '100%',
    }}
  >
    <img
      alt="M/Y Christina"
      style={{
        display: 'block',
        width: '100%',
        position: 'absolute',
        left: 0,
        top: 0,
      }}
      src={require('@images/jumbotron-1.jpg')}
    />
  </picture>
)

export default JumboImage
