import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Helmet from 'react-helmet'
import { FaBars } from 'react-icons/fa'
import { reveal as Menu } from 'react-burger-menu'
import { Visible } from 'react-grid-system'

import Container from '@components/Container'
import GentlyAppear from '@components/GentlyAppear'
import Footer from '@components/Footer'
import Header from '@components/Header'
import IconButton from '@components/IconButton'
import Jumbotron from '@components/Jumbotron'
import Navigation from '@components/Navigation'
import PaddedBlock from '@components/PaddedBlock'
import Sticky from '@components/Sticky'

import styles from './Layout.module.css'

class Layout extends React.PureComponent {
  state = {
    open: false,
    mapFrame: null,
  }
  componentDidMount() {
    const mapFrame = (
      <iframe
        title="M/Y Christina on Google Maps"
        width="100%"
        frameBorder="0"
        className={styles.frame}
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJWeQ_lLg3WBQRfPirhhUwaZA&key=AIzaSyDc1FmN-9u4yqfD7eRYgfa7o4Q_J6LoSvc&language=en"
        allowFullScreen
      />
    )
    this.setState({ mapFrame })
  }
  openBurger = () => {
    this.setState({ open: true })
  }
  closeBurger = () => {
    this.setState({ open: false })
  }
  renderJumbotron() {
    return <Jumbotron>{this.props.location.pathname !== '/reach-us/' ? null : this.state.mapFrame}</Jumbotron>
  }
  renderMobileView() {
    return (
      <div id="outer-container">
        <Menu
          isOpen={this.state.open}
          pageWrapId="page-wrap"
          outerContainerId="outer-container"
          onStateChange={({ isOpen }) => this.setState({ open: isOpen })}
        >
          <Navigation activeSlug={this.props.location.pathname} onRequestCloseMobileNavigation={this.closeBurger} />
        </Menu>
        <div id="page-wrap" className={styles.pageWrapper}>
          <Header
            insertBefore={
              <IconButton onClick={this.openBurger}>
                <FaBars />
              </IconButton>
            }
          />
          {this.renderJumbotron()}
          <Container className={styles.mobileContainer}>
            <main className={styles.mobileMain}>
              <GentlyAppear location={this.props.location}>{this.props.children}</GentlyAppear>
            </main>
          </Container>
          <Footer />
        </div>
      </div>
    )
  }
  renderDesktopView() {
    return (
      <div className={styles.desktop}>
        <Header />
        {this.renderJumbotron()}
        <Container className={styles.desktopContainer}>
          <div className={cx(styles.contentWrapper)}>
            <PaddedBlock className={styles.desktopContent}>
              <Sticky top={96}>
                <Navigation activeSlug={this.props.location.pathname} />
              </Sticky>
            </PaddedBlock>
            <main className={styles.desktopMain}>
              <GentlyAppear location={this.props.location}>{this.props.children}</GentlyAppear>
            </main>
          </div>
        </Container>
        <Footer />
      </div>
    )
  }
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <link
            href="https://fonts.googleapis.com/css?family=Montserrat:300,500,600|Playfair+Display"
            rel="stylesheet"
          />
        </Helmet>
        <Visible xs sm>
          {this.renderMobileView()}
        </Visible>
        <Visible md lg xl>
          {this.renderDesktopView()}
        </Visible>
      </React.Fragment>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  activeSlug: PropTypes.string,
}

Layout.defaultProps = {
  style: {},
}

export default Layout
