import React from 'react'

import { FaAnchor, FaFacebookSquare } from 'react-icons/fa'
import { MdPhone, MdLocationOn, MdGpsFixed, MdMailOutline, MdWhatsapp } from 'react-icons/md'

import CategoryLabel from '@components/CategoryLabel'
import CategoryLink from '@components/CategoryLink'

import styles from './ContactInfo.module.css'

export default {
  brand: (
    <div className={styles.brand}>
      <CategoryLabel iconBefore={FaAnchor}>M/Y Christina</CategoryLabel>
    </div>
  ),
  phone: (
    <React.Fragment>
      <CategoryLabel iconBefore={MdPhone}>Phone</CategoryLabel>
      <div>
        <CategoryLink external to="tel:+201222118533">
          (+20) 122 211 8533
        </CategoryLink>
      </div>
      <div>
        <CategoryLink external to="tel:+200222584857">
          (+20) 022 258 4857
        </CategoryLink>
      </div>
    </React.Fragment>
  ),
  email: (
    <React.Fragment>
      <CategoryLabel iconBefore={MdMailOutline}>Email</CategoryLabel>
      <CategoryLink external to="mailto:yachtchristina@gmail.com">
        yachtchristina@gmail.com
      </CategoryLink>
    </React.Fragment>
  ),
  chat: (
    <React.Fragment>
      <CategoryLabel iconBefore={MdWhatsapp}>Chat</CategoryLabel>
      <CategoryLink external to="https://wa.me/201222118533">
        via WhatsApp at
        <br />
        (+20) 122 211 8533
      </CategoryLink>
    </React.Fragment>
  ),
  address: (
    <React.Fragment>
      <CategoryLabel iconBefore={MdLocationOn}>Location</CategoryLabel>
      <div>
        <CategoryLink external to="https://maps.google.com/maps?ll=30.039662,31.220365&z=17&cid=10405901283028105340">
          110 Nile Street. Dokki, Egypt.
          <br />
          <em>
            Next to El Galaa Bridge and
            <br />
            in front of Cairo Sheraton Hotel
          </em>
          .
        </CategoryLink>
      </div>
      <br />
      <div>
        <CategoryLink
          external
          to="https://maps.google.com/maps?ll=30.039662,31.220365&z=17&cid=10405901283028105340"
          className={styles.arabic}
        >
          ١١٠ ش كورنيش النيل، الدقى، الجيزة
          <br />
          أمام فندق شيراتون القاهرة
        </CategoryLink>
      </div>
    </React.Fragment>
  ),
  gps: (
    <React.Fragment>
      <CategoryLabel iconBefore={MdGpsFixed}>GPS Coordinates</CategoryLabel>
      <CategoryLink external to="https://maps.google.com/maps?ll=30.039662,31.220365&z=17&cid=10405901283028105340">
        Latitude: 30.039662
        <br />
        Longitude: 31.220365
      </CategoryLink>
    </React.Fragment>
  ),
  social: (
    <React.Fragment>
      <CategoryLabel iconBefore={FaFacebookSquare}>Social</CategoryLabel>
      <CategoryLink external to="https://www.facebook.com/pages/Christina-yacht/526372210718910">
        Check out our <br />
        Fan Page on <b>Facebook</b>
      </CategoryLink>
    </React.Fragment>
  ),
}
