import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, Transition } from 'react-transition-group'

const DURATION = 250

const styles = {
  entering: {
    position: 'absolute',
    opacity: 0,
  },
  entered: {
    transition: `opacity ${DURATION}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${DURATION}ms ease-in-out`,
    opacity: 0,
  },
}

const GentlyAppear = ({ children, location }) => (
  <TransitionGroup>
    <Transition
      key={location.pathname}
      timeout={{
        enter: DURATION,
        exit: DURATION,
      }}
    >
      {status => <div style={{ ...styles[status] }}>{children}</div>}
    </Transition>
  </TransitionGroup>
)

GentlyAppear.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
}

GentlyAppear.defaultProps = {
  location: { pathname: Math.random() },
}

export default GentlyAppear
