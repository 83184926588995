import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { Row, Col } from 'react-grid-system'

import ContactInfo from '@components/ContactInfo'
import Container from '@components/Container'
import PaddedBlock from '@components/PaddedBlock'
import Link from '@components/Link'

import styles from './Footer.module.css'

const Footer = ({ className, style }) => (
  <footer className={cx(styles.root, className)}>
    <address className={styles.address}>
      <Container>
        <PaddedBlock>
          <Row className={styles.contacts}>
            <Col sm={4}>
              <ContactInfo type="phone" />
              <ContactInfo type="address" />
            </Col>
            <Col sm={4}>
              <ContactInfo type="chat" />
              <ContactInfo type="email" />
              <ContactInfo type="social" />
            </Col>
            <Col sm={4} className={styles.lastColumn}>
              <div className={styles.marginFix}>
                <picture className={styles.drawing}>
                  <img alt="Motor Yatch Christina" src={require('@images/drawing.png')} />
                </picture>
                <span>
                  © M/Y Christina 1992-{new Date().getFullYear()}
                  <br />
                  All rights reserved
                </span>
              </div>
            </Col>
          </Row>
        </PaddedBlock>
      </Container>
    </address>
    <div className={styles.made}>
      <Link external to="http://pierre.cx">
        made with love
      </Link>
      <div className={styles.hearts}>
        <div className={cx(styles.heart, styles.heart1)}>&hearts;</div>
        <div className={cx(styles.heart, styles.heart2)}>&hearts;</div>
        <div className={cx(styles.heart, styles.heart3)}>&hearts;</div>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Footer
