// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-gallery-index-js": () => import("./../../../src/pages/gallery/index.js" /* webpackChunkName: "component---src-pages-gallery-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reach-us-index-js": () => import("./../../../src/pages/reach-us/index.js" /* webpackChunkName: "component---src-pages-reach-us-index-js" */),
  "component---src-pages-reservation-index-js": () => import("./../../../src/pages/reservation/index.js" /* webpackChunkName: "component---src-pages-reservation-index-js" */),
  "component---src-pages-reviews-index-js": () => import("./../../../src/pages/reviews/index.js" /* webpackChunkName: "component---src-pages-reviews-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */)
}

