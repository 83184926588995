import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Button from '@components/Button'
import Container from '@components/Container'
import JumboImage from '@components/JumboImage'

import styles from './Jumbotron.module.css'

const Jumbotron = ({ children, className, style }) => (
  <div className={cx(styles.root, { [styles.hasChildren]: children }, className)} style={style}>
    {children ? children : <JumboImage />}
    <Container>
      <div className={styles.wrapper}>
        <div className={styles.text}>
          <span>Cruise the Nile river in style!</span>
          <Button to="/reservation/">Book now</Button>
        </div>
      </div>
    </Container>
  </div>
)

Jumbotron.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Jumbotron
