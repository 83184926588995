import { FaHandHoldingHeart } from 'react-icons/fa'
import { MdHome, MdPhotoLibrary, MdStar, MdBook, MdMyLocation } from 'react-icons/md'

export default [
  {
    to: '/',
    icon: MdHome,
    label: 'Welcome',
  },
  {
    to: '/services/',
    icon: FaHandHoldingHeart,
    label: 'Services',
  },
  {
    to: '/gallery/',
    icon: MdPhotoLibrary,
    label: 'Gallery',
  },
  {
    to: '/reviews/',
    icon: MdStar,
    label: 'Reviews',
  },
  {
    to: '/reservation/',
    icon: MdBook,
    label: 'Reservation',
  },
  {
    to: '/reach-us/',
    icon: MdMyLocation,
    label: 'Reach us',
  },
]
