import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { MdPhone } from 'react-icons/md'
import { Visible, Hidden } from 'react-grid-system'

import Container from '@components/Container'
import IconButton from '@components/IconButton'
import PaddedBlock from '@components/PaddedBlock'
import Touchable from '@components/Touchable'

import styles from './Header.module.css'

const Header = ({ className, style, insertBefore }) => (
  <header className={cx(styles.root, className)} style={style}>
    <Container>
      <PaddedBlock>
        <div className={styles.content}>
          {insertBefore}
          <Touchable to="/" scrollToMainContent={false}>
            <h1 className={styles.brand}>
              <abbr title="Motor Yatch">M/Y</abbr>
              <span>Christina</span>
            </h1>
          </Touchable>
          <div className={styles.contact}>
            <Visible xs sm>
              <IconButton external to="tel:+201222118533">
                <MdPhone />
              </IconButton>
            </Visible>
            <Hidden xs sm>
              <Touchable to="tel:+201222118533" external className={styles.cta}>
                <span className={styles.value}>(+20) 122-211-8533</span>
                <small className={styles.label}>Any questions? Call us!</small>
              </Touchable>
            </Hidden>
          </div>
        </div>
      </PaddedBlock>
    </Container>
  </header>
)

Header.propTypes = {
  insertBefore: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Header
