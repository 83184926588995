import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import fragments from './ContactInfo.fragments'
import styles from './ContactInfo.module.css'

const ContactInfo = ({ single, type, className, style }) => (
  <div className={cx(styles.root, { [styles.single]: single }, className)} style={style}>
    {fragments[type] || null}
  </div>
)

ContactInfo.propTypes = {
  type: PropTypes.oneOf(['brand', 'phone', 'email', 'chat', 'address', 'gps', 'social']),
  single: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default ContactInfo
