import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import { FaChevronRight } from 'react-icons/fa'

import NavItem from '@components/NavItem'

import data from './Navigation.data.js'
import styles from './Navigation.module.css'

const Navigation = ({ onRequestCloseMobileNavigation, activeSlug, className, style }) => (
  <div className={cx(styles.root, className)} style={style}>
    {data.map(link => {
      const Icon = link.icon
      const isActive = activeSlug === link.to

      return (
        <NavItem
          key={link.to}
          to={link.to}
          isActive={isActive}
          className={cx(styles.navItem, { [styles.active]: isActive })}
          onClick={onRequestCloseMobileNavigation}
          scrollToMainContent={link.to !== '/reach-us/'}
        >
          <Icon className={styles.iconBefore} />
          <span className={cx(styles.label, styles.navItemLabel)}>{link.label}</span>
          <FaChevronRight className={styles.iconAfter} />
        </NavItem>
      )
    })}
  </div>
)

Navigation.propTypes = {
  onRequestCloseMobileNavigation: PropTypes.func,
  activeSlug: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}

Navigation.defaultProps = {
  onRequestCloseMobileNavigation: () => {},
}

export default Navigation
