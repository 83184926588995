import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Touchable from '@components/Touchable'

import styles from './NavItem.module.css'

const NavItem = ({ isActive, className, ...rest }) => (
  <Touchable
    className={cx(styles.root, {
      [styles.active]: isActive,
      [className]: className,
    })}
    {...rest}
  />
)

NavItem.propTypes = {
  isActive: PropTypes.bool,
  className: PropTypes.string,
}

export default NavItem
